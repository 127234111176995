import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout-minhelt/layout";
import Seo from "../components/Seo";
import getMetatags from "../util/getMetatags";
import Hero from "../components/hero";
import YouTube from "react-youtube";
import Carusel from "../components/carusel";

const NewsItemPage = ({ data, ...props }) => {
  //console.log(data);

  const node = data.node;
  const seo = getMetatags(
    node.metatag /* , nodeOverview.relationships.OGImage?.localFile?.publicURL */
  );

  const hero = {
    title: "",
  };

  const pdf = {
    title: node?.pdf?.description,
    url: node?.relationships?.pdf?.localFile?.url,
  };

  const images1 = node?.relationships?.image?.localFile?.url
    ? [
        {
          url: node?.relationships?.image?.localFile?.url,
          ...node.image,
        },
      ]
    : [];

  const images2 =
    node?.relationships?.images && node?.relationships?.images.length > 0
      ? node.relationships.images.map((image, index) => ({
          url: image.localFile.url,
          ...node.images[index],
        }))
      : [];

  const images = [...images1, ...images2];

  const videoOpts = {
    //height: '195',
    width: "100%",
    // playerVars: {
    //   // https://developers.google.com/youtube/player_parameters
    //   autoplay: 1,
    // },
  };

  return (
    <Layout>
      <Seo {...seo} />

      <Hero
        {...hero}
        className=" mt-[90px] lg:mt-[126px] bg-hero "
        url={"/static/17bc7adb5ac4b2160df8786eda65a8fb/kishray_memshal-2.jpg"}
      />
      <section>
        <div className="container">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-4 py-12 lg:py-32 space-y-4 lg:space-y-0">
            <div className="lg:w-1/2 lg:min-h-[400px] space-y-8">
              <h1 className="text-[34px] font-bold text-[#2170bc]">
                {node.title}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: node.description }} />
            </div>
            <div className="lg:w-1/2  lg:min-h-[400px]">
              <div className="space-y-8">
                <div>
                  <Carusel
                    items={images.map((image, index) => (
                      <div className="mx-auto flex items-center justify-center">
                        <img
                          src={image.url}
                          alt={image.alt}
                          key={index}
                          className="inline-block  h-[400px] border"
                        />
                      </div>
                    ))}
                  />
                </div>

                {node?.youtube?.video_id && (
                  <div>
                  <YouTube videoId={node.youtube.video_id} opts={videoOpts} />
                  </div>
                )}

                {pdf.url && (
                  <div>
                    <span>קובץ להורדה: </span>
                    <a href={pdf.url} target="__blank" className="underline">
                      {pdf.title}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NewsItemPage;

// export const Head = () => <title>Basic1 Page</title>;

export const query = graphql`
  query NewsItem($id: String!) {
    node: nodeNews(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      description: field_description
      iamge: field_image {
        alt
        title
      }
      images: field_images {
        alt
        title
      }
      field_link {
        title
        uri
      }
      pdf: field_pdf {
        description
      }
      youtube: field_youtube_video {
        input
        video_id
      }
      relationships {
        image: field_image {
          localFile {
            url
          }
        }
        images: field_images {
          localFile {
            url
          }
        }
        pdf: field_pdf {
          localFile {
            url
          }
        }
      }
    }
  }
`;
